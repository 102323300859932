export const FirebaseConfig = {
	"projectId": "exicube-hired-by-compass-taxi",
	"appId": "1:547141528296:web:357dab4ccbe3ed3cc4375d",
	"databaseURL": "https://exicube-hired-by-compass-taxi-default-rtdb.firebaseio.com",
	"storageBucket": "exicube-hired-by-compass-taxi.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyBANhWbK0Ws3nU2y6d9MDMYMjeQwKJnid4",
	"authDomain": "exicube-hired-by-compass-taxi.firebaseapp.com",
	"messagingSenderId": "547141528296",
	"measurementId": "G-3D38QG89HH"
};